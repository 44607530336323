.purchase-order-details-title {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 34px;
}

#purchase-order-details-number {
    font-style: normal;
    font-weight: bold;
    color: #000;
    line-height: 15px !important;
    font-size: 20px
}

#text-right {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: rgba(40, 45, 38, 0.5)
}

#text-right1 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* line-height: 9px; */
    color: rgba(40, 45, 38, 0.5)
}

#title-vendor-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px
}

#text-right2 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(40, 45, 38, 0.5)
}

#text-right3 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: rgba(40, 45, 38, 0.5)
}

#text-right4 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: rgba(40, 45, 38, 0.5)
}

#purchase-order-details-right-side {
    text-align: right;
}

#purchase-order-details-status {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: rgba(40, 45, 38, 0.5);
    margin-top: 20px;
    line-height: 34px;
    margin-bottom: 2px
}

#purchase-order-details-status-value {
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    margin-top: 3px;
    margin-left: 8px
}

#purchase-order-details-status-button {
    background-color: #60579A;
    border-radius: 8px;
    box-shadow: rgb(16 24 40 / 5%) 0px 1px 2px;
    width: 23px;
    height: 25px;
}

#purchase-order-details-status-button-text {
    color: white;
    font-size: 11px;
    font-weight: 500
}



#purchase-order-details-status-value2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    margin-top: 3px;
    margin-left: 7px;
}

#purchase-order-details-status-value3 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 34px;
    margin-top: 8px;
    color: rgba(40, 45, 38, 0.5)
}

#purchase-order-details-status-value33 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 34px;
    margin-top: 8px;
    color: black
}

#purchase-order-details-status-value4 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 34px;
    margin-top: 8px;
    color: rgba(40, 45, 38, 0.5)
}

#purchase-order-details-status-value5 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 10px
}

#purchase-order-details-status-value6 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 10px
}

#purchase-order-details-footer {
    margin-left: 80%;
    margin-top: 8px;
}

#purchase-order-details-footer2 {
    margin-left: 66%;
}

#purchase-order-details-footer-subtotal {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin-bottom: 6px;
    margin-top: 21px
}

#purchase-order-details-footer-tax {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin-bottom: 2px;
    margin-top: 3px;
}

#purchase-order-details-footer-shipping {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    MARGIN-TOP: 5PX
}

#purchase-order-details-footer-subtotal-value {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin-bottom: 6px;
    margin-top: 21px
}

#purchase-order-details-footer-tax-value {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin-bottom: 2px;
    margin-top: 3px;
}

#purchase-order-details-footer-shipping-value {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    MARGIN-TOP: 5PX
}


#purchase-order-details-footer-total-value {
    margin-left: 36%;
}