/* .App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
*/

.App-logo {
  width: 100px;
  height: 100px;
  pointer-events: none;
}

.sort-table {
  font-size: 18px !important;
  margin-top: 3px;
}

.sort-asc {
  transform: rotate(0deg);
}

.sort-desc {
  transform: rotate(180deg);
}

.button {
  background-color: rgb(96, 87, 154) !important;
  color: white !important;
  border-radius: 10px !important;
  padding: 5px 43px !important;
  height: 3em !important;
}

.cancel-button {
  background-color: white !important;
  color: black !important;
  border: 1px solid rgba(15, 15, 15, 0.5) !important;
  border-radius: 10px !important;
  padding: 10px 25px !important;
  height: 3em !important;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 1s linear;
  }

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.PE_sidebar::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

.PE_sidebar .MuiListItemIcon-root {
  min-width: 40px !important;
}

.PE_sidebar .MuiListItem-root {
  padding-top: 4px;
  padding-bottom: 4px;
}