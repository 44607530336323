#invoice-container {
    display: flex;
    justify-content: space-between;
}

#headder-left-side-second {

    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    margin-top: 0px;
}

#headder-left-side-first {

    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0px;
    /* line-height: 24px; */
}

#invoive-headder {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px
}

#invoive-headder-second {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgba(40, 45, 38, 0.5);
    line-height: 16px
}

#invoive-headder-second-panding {
    color: rgb(96, 87, 154);
    margin-left: 2px;
    font-size: 14px
}

#invoice-headder-image {
    width: 140px;
    height: 48px
}

#download-invoice-button {
    background-color: rgb(96, 87, 154);
    border-radius: 10px;
}

#download-button-link {
    text-decoration: none;
}

#invoice-date {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: rgba(40, 45, 38, 0.5)
}

#invoice-date-value {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}

#invoice-number-value {
    font-style: normal;
    font-weight: 600;
    font-size: 12px
}

#invoice-number {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: rgba(40, 45, 38, 0.5)
}

#invoive-number-value {
    font-style: normal;
    font-weight: 600;
    font-size: 12px
}

#invoice-to {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: rgba(40, 45, 38, 0.5)
}

#invoice-to-name {
    font-style: normal;
    font-weight: 600;
    font-size: 12px
}

#invoice-to-address {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: rgba(40, 45, 38, 0.5)
}

#table-container {
    margin-top: 34px;
    border: 1.4px solid rgba(224, 224, 224, 1);
    border-radius: 10px;
}

#purchase-order-invoice-footer {
    margin-left: 75%;
    margin-top: 8px;
}

#purchase-order-details-footer2 {
    margin-left: 73%;
}

#purchase-order-details-footer-subtotal {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin-bottom: 6px;
    margin-top: 21px
}

#purchase-order-details-footer-tax {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin-bottom: 2px;
    margin-top: 3px;
}

#purchase-order-details-footer-shipping {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    MARGIN-TOP: 5PX
}

#purchase-order-details-footer-subtotal-value {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin-bottom: 6px;
    margin-top: 21px
}

#purchase-order-details-footer-tax-value {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin-bottom: 2px;
    margin-top: 3px;
}

#purchase-order-details-footer-shipping-value {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    MARGIN-TOP: 5PX
}


#purchase-order-details-footer-total-value {
    margin-left: 66%;
    color: rgb(96, 87, 154);
}