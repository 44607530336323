body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-button-container {
  display: flex;
  justify-content: flex-end;
}
.modal-title {
  font-weight: 700;
  font-size: 20px;
  margin-top: 0px;}

.form-action-button {
  display: flex;
  align-items: center;
  border: none;
  background-color: #FFFFFF;
  border: 1px solid rgba(40, 45, 38, 0.05);
  padding: 10px 19px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.form-action-button-submit {
  display: flex;
  align-items: center;
  border: none;
  background-color: #60579A;
  padding: 10px 19px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
}

.MuiTabPanel-root {
  padding: 0!important;
}

.css-a5rdam-MuiGrid-root {
  display: block!important;
  margin-left: 0px!important;
}